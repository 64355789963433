/* Centramos la aplicación en la página */
.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #ebd9ed; /* Color violeta claro */
  font-family: 'Arial', sans-serif;
  min-height: 100vh; /* Asegurar que cubre toda la altura de la pantalla */
}

/* Contenedor principal del formulario */
.form-container {
  background-color: #ebd9ed; /* Fondo violeta claro */
  padding: 20px;
  border: 3px solid #000000; /* Borde de 2px con color violeta oscuro */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

/* Estilo de cada sección del formulario */
.form-section {
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Estilo del título del formulario */
h1 {
  color: #202124;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadce0;
}

/* Estilo general del formulario */
form {
  display: flex;
  flex-direction: column;
}

/* Estilo de las etiquetas de las preguntas */
label {
  display: block;
  margin-bottom: 10px;
  color: #202124;
}

/* Alinear preguntas de opción múltiple en una fila */
.form-group-multiple {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group-multiple .choices {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-group-multiple .choices label {
  display: flex;
  align-items: center;
  gap: 5px;
}

input[type="text"],
input[type="date"],
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #dadce0;
  border-radius: 4px;
}

/* Estilo específico para textarea */
textarea {
  height: 100px;
}

/* Estilo de las opciones de radio */
input[type="radio"] {
  margin-right: 10px;
}

/* Estilo del botón de envío */
button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

/* Hover del botón */
button:hover {
  background-color: #1558b3;
}

/* Estilo de los encabezados de sección */
h2 {
  color: #202124;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dadce0;
}
.btn-submit {
  background-color: #4CAF50;
  color: white;
  padding: 15px 20px;
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.4s;
}

.btn-submit:hover {
  background-color: #45a049;
}
/* Estilos para el spinner */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px; /* Tamaño del cuadro */
  padding: 20px;
  background-color: white; /* Fondo blanco */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 1); /* Sombra negra */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centrar el cuadro */
  text-align: center;
  height: auto; /* Dejar que el contenido defina la altura */
}

.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px 0; /* Espacio entre el spinner y el texto */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading h1 {
  color: #202124;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px; /* Espacio debajo del título */
}

.loading p {
  font-size: 16px;
  margin-top: 10px;
  color: #555;
}